body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.content {
}

.imgfit {
  padding-top: 5;
  display: block;
  max-width: 100%;
  max-height: auto;
  margin: 0 auto;
}

.imglandscape {
  height: 30%;
}

.imgportrait {
  height: 30%;
}

.youtube {
	margin-top: 0px;
	margin-bottom: 0px;
        height: auto;
        width: auto;
        display:block;
}

.s3videoportrait {
  max-width: 70vw;
  max-height: 80vh;
  object-fit: cover;
}
.s3videolandscape {
  max-width: 85vw;
  max-height: 60vh;
  object-fit: cover;
	margin-left: 0;
	margin-right: 30px;
}

#fullpagebutton {
  margin-top: 30px;
  margin-left: 20px;
  background-color: white;
}

#fullpage {
  display: none;
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-size: contain;
  background-repeat: no-repeat no-repeat;
  background-position: center center;
  background-color: black;
}

.conversation {
   margin-top: 70px;
}

@media screen and (min-width: 800px) {
  .conversation {
    margin:20px 20%;
    margin-top: 70px;
  }
  .conversation2 {
    margin-top: 0px;
  }
  .overall {
    margin:20px 20px;
    margin-top: 0px;
  }
  .content {
    margin:auto;
    display: grid;
  }
  .title {
    margin:20px 0;
  }
}

@media screen and (orientation: portrait) {
.youtube {
	margin: 0 auto;
	padding: 10px;
        width: 100%;
        display:block;
}
.imgportrait {
  transform: translate(-50%);
}
}

@media screen and (orientation: landscape) {
.youtube {
	margin: 0 auto;
	padding: 10px;
        width: calc(100% - 100px);
        display:block;
}

}

.sideMenu {
   height: 100%;
   width: 300px;
   position: fixed;
   top: 0;
   left: 0;
   background: white;
   transition: 0.5s;
   margin-left: -300px;
   border:thin solid black;
}

.sideMenu ul {
  list-style: none;
  padding: 0;
  margin-top: 10px;
  margin-left: 10px;
}

.sideMenu button {
   display: block;
   margin-bottom: 20px;
}

.sideMenu p {
   font-size:1.3rem;
}

.main {
    transition: 0.5s;
}
.main {
   margin-top: 45px;
}



.stickytop {
	position: fixed;
	background-color: white;
	top: 0;
	width: 100%;
	padding-bottom: 5px;
	padding-top: 5px;
	padding-left: 5px;
	z-index: 1;
}

.remaining {
	top: 65px;
	position: relative;
}


.likebutton {
   transition : 0.2s;
}

.scrollable {
    scroll-margin-top: 90px;
}

input[type="file"] {
	display: none
}

.uploadinfo {
   bottom: 0;
   position: fixed;
   z-index: 1;
   background-color: white;
   padding-bottom: 5px;
   padding-top: 5px;
   padding-left: 5px;
}
